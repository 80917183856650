import React, {useEffect} from "react"
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import * as gdprStyles from '../styles/gdpr.module.scss';


// markup
const Impressum = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    })

  return (
    <>
      <Navbar/>
        <div className={gdprStyles.gdprContent}>
            <div className="container-sm">
            <h1>Datenschutzerkl&auml;rung</h1>
                <br/>
            <p>Diese Datenschutzerkl&auml;rung kl&auml;rt Sie &uuml;ber die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz &bdquo;Daten&ldquo;) im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepr&auml;senzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als &bdquo;Onlineangebot&ldquo;). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. &bdquo;Verarbeitung&ldquo; oder &bdquo;Verantwortlicher&ldquo; verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).</p>
                <br/>
                <h3>Verantwortlicher</h3>
                <br/>
            <p>Moghancy<br />Amir Moghaddam<br />10245 Berlin<br />E-Mail: info@moghancy.com<br />Tel: +49 152 54823091</p>
                <br/>
                <h3>Arten der verarbeiteten Daten</h3>
                <br/>
            <p>&ndash; Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).<br />&ndash; Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />&ndash; Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br />&ndash; Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br />&ndash; Meta-/Kommunikationsdaten (z.B., Ger&auml;te-Informationen, IP-Adressen).</p>
                <br/>
            <h3>Kategorien betroffener Personen</h3>
                <br/>
            <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als &bdquo;Nutzer&ldquo;).</p>
                <br/>
                <h3>Zweck der Verarbeitung</h3>
                <br/>
            <p>&ndash; Zurverf&uuml;gungstellung des Onlineangebotes, seiner Funktionen und Inhalte.<br />&ndash; Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br />&ndash; Sicherheitsma&szlig;nahmen.<br />&ndash; Reichweitenmessung/Marketing</p>
                <br/>
            <h3>Verwendete Begrifflichkeiten</h3>
                <br/>
            <p>&bdquo;Personenbezogene Daten&ldquo; sind alle Informationen, die sich auf eine identifizierte oder identifizierbare nat&uuml;rliche Person (im Folgenden &bdquo;betroffene Person&ldquo;) beziehen; als identifizierbar wird eine nat&uuml;rliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind.</p>
            <p>&bdquo;Verarbeitung&ldquo; ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgef&uuml;hrte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.</p>
            <p>&bdquo;Pseudonymisierung&ldquo; die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zus&auml;tzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden k&ouml;nnen, sofern diese zus&auml;tzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Ma&szlig;nahmen unterliegen, die gew&auml;hrleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren nat&uuml;rlichen Person zugewiesen werden.</p>
            <p>&bdquo;Profiling&ldquo; jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte pers&ouml;nliche Aspekte, die sich auf eine nat&uuml;rliche Person beziehen, zu bewerten, insbesondere um Aspekte bez&uuml;glich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, pers&ouml;nliche Vorlieben, Interessen, Zuverl&auml;ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser nat&uuml;rlichen Person zu analysieren oder vorherzusagen.</p>
            <p>Als &bdquo;Verantwortlicher&ldquo; wird die nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.</p>
            <p>&bdquo;Auftragsverarbeiter&ldquo; eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</p>
                <br/>
            <h3>Ma&szlig;gebliche Rechtsgrundlagen</h3>
                <br/>
            <p>Nach Ma&szlig;gabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. F&uuml;r Nutzer aus dem Geltungsbereich der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern die Rechtsgrundlage in der Datenschutzerkl&auml;rung nicht genannt wird, Folgendes: <br />Die Rechtsgrundlage f&uuml;r die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;<br />Die Rechtsgrundlage f&uuml;r die Verarbeitung zur Erf&uuml;llung unserer Leistungen und Durchf&uuml;hrung vertraglicher Ma&szlig;nahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;<br />Die Rechtsgrundlage f&uuml;r die Verarbeitung zur Erf&uuml;llung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;<br />F&uuml;r den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.<br />Die Rechtsgrundlage f&uuml;r die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO. <br />Die Rechtsgrundlage f&uuml;r die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. <br />Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie ehoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO. <br />Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.</p>
                <br/>
            <h3>Sicherheitsma&szlig;nahmen</h3>
                <br/>
            <p>Wir treffen nach Ma&szlig;gabe der gesetzlichen Vorgabenunter Ber&uuml;cksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umst&auml;nde und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos f&uuml;r die Rechte und Freiheiten nat&uuml;rlicher Personen, geeignete technische und organisatorische Ma&szlig;nahmen, um ein dem Risiko angemessenes Schutzniveau zu gew&auml;hrleisten.</p>
            <p>Zu den Ma&szlig;nahmen geh&ouml;ren insbesondere die Sicherung der Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verf&uuml;gbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, L&ouml;schung von Daten und Reaktion auf Gef&auml;hrdung der Daten gew&auml;hrleisten. Ferner ber&uuml;cksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
                <br/>
                <h3>Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und Dritten</h3>
                <br/>
            <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegen&uuml;ber anderen Personen und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an diese &uuml;bermitteln oder ihnen sonst Zugriff auf die Daten gew&auml;hren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine &Uuml;bermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserf&uuml;llung erforderlich ist), Nutzer eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).</p>
            <p>Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, &uuml;bermitteln oder ihnen sonst den Zugriff gew&auml;hren, erfolgt dies insbesondere zu administrativen Zwecken als berechtigtes Interesse und dar&uuml;berhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden Grundlage.</p>
                <br/>
            <h3>&Uuml;bermittlungen in Drittl&auml;nder</h3>
                <br/>
            <p>Sofern wir Daten in einem Drittland (d.h. au&szlig;erhalb der Europ&auml;ischen Union (EU), des Europ&auml;ischen Wirtschaftsraums (EWR) oder der Schweizer Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. &Uuml;bermittlung von Daten an andere Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es zur Erf&uuml;llung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der gesetzlichen Voraussetzungen. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. f&uuml;r die USA durch das &bdquo;Privacy Shield&ldquo;) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen.</p>
                <br/>
                <h3>Rechte der betroffenen Personen</h3>
                <br/>
            <p>Sie haben das Recht, eine Best&auml;tigung dar&uuml;ber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft &uuml;ber diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.</p>
            <p>Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollst&auml;ndigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.</p>
            <p>Sie haben nach Ma&szlig;gabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverz&uuml;glich gel&ouml;scht werden, bzw. alternativ nach Ma&szlig;gabe der gesetzlichen Vorgaben eine Einschr&auml;nkung der Verarbeitung der Daten zu verlangen.</p>
            <p>Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Ma&szlig;gabe der gesetzlichen Vorgaben zu erhalten und deren &Uuml;bermittlung an andere Verantwortliche zu fordern.</p>
            <p>Sie haben ferner nach Ma&szlig;gabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde einzureichen.</p>
                <br/>
            <h3>Widerrufsrecht</h3>
                <br/>
            <p>Sie haben das Recht, erteilte Einwilligungen mit Wirkung f&uuml;r die Zukunft zu widerrufen.</p>
                <br/>
            <h3>Widerspruchsrecht</h3>
                <br/>
            <p>Sie k&ouml;nnen der k&uuml;nftigen Verarbeitung der Sie betreffenden Daten nach Ma&szlig;gabe der gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung f&uuml;r Zwecke der Direktwerbung erfolgen.</p>
                <br/>
            <h3>Cookies und Widerspruchsrecht bei Direktwerbung</h3>
                <br/>
            <p>Als &bdquo;Cookies&ldquo; werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies k&ouml;nnen unterschiedliche Angaben gespeichert werden. Ein Cookie dient prim&auml;r dazu, die Angaben zu einem Nutzer (bzw. dem Ger&auml;t auf dem das Cookie gespeichert ist) w&auml;hrend oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als tempor&auml;re Cookies, bzw. &bdquo;Session-Cookies&ldquo; oder &bdquo;transiente Cookies&ldquo;, werden Cookies bezeichnet, die gel&ouml;scht werden, nachdem ein Nutzer ein Onlineangebot verl&auml;sst und seinen Browser schlie&szlig;t. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden. Als &bdquo;permanent&ldquo; oder &bdquo;persistent&ldquo; werden Cookies bezeichnet, die auch nach dem Schlie&szlig;en des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso k&ouml;nnen in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die f&uuml;r Reichweitenmessung oder Marketingzwecke verwendet werden. Als &bdquo;Third-Party-Cookie&ldquo; werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von &bdquo;First-Party Cookies&ldquo;).</p>
            <p>Wir k&ouml;nnen tempor&auml;re und permanente Cookies einsetzen und kl&auml;ren hier&uuml;ber im Rahmen unserer Datenschutzerkl&auml;rung auf.</p>
            <p>Falls die Nutzer nicht m&ouml;chten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies k&ouml;nnen in den Systemeinstellungen des Browsers gel&ouml;scht werden. Der Ausschluss von Cookies kann zu Funktionseinschr&auml;nkungen dieses Onlineangebotes f&uuml;hren.</p>
            <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, &uuml;ber die US-amerikanische Seite <a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a> oder die EU-Seite <a href="https://www.youronlinechoices.com/">https://www.youronlinechoices.com/</a> erkl&auml;rt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden k&ouml;nnen.</p>
                <br/>
            <h3>L&ouml;schung von Daten</h3>
                <br/>
            <p>Die von uns verarbeiteten Daten werden nach Ma&szlig;gabe der gesetzlichen Vorgaben gel&ouml;scht oder in ihrer Verarbeitung eingeschr&auml;nkt. Sofern nicht im Rahmen dieser Datenschutzerkl&auml;rung ausdr&uuml;cklich angegeben, werden die bei uns gespeicherten Daten gel&ouml;scht, sobald sie f&uuml;r ihre Zweckbestimmung nicht mehr erforderlich sind und der L&ouml;schung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>
            <p>Sofern die Daten nicht gel&ouml;scht werden, weil sie f&uuml;r andere und gesetzlich zul&auml;ssige Zwecke erforderlich sind, wird deren Verarbeitung eingeschr&auml;nkt. D.h. die Daten werden gesperrt und nicht f&uuml;r andere Zwecke verarbeitet. Das gilt z.B. f&uuml;r Daten, die aus handels- oder steuerrechtlichen Gr&uuml;nden aufbewahrt werden m&uuml;ssen.</p>
                <br/>
            <h3>&Auml;nderungen und Aktualisierungen der Datenschutzerkl&auml;rung</h3>
                <br/>
            <p>Wir bitten Sie sich regelm&auml;&szlig;ig &uuml;ber den Inhalt unserer Datenschutzerkl&auml;rung zu informieren. Wir passen die Datenschutzerkl&auml;rung an, sobald die &Auml;nderungen der von uns durchgef&uuml;hrten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die &Auml;nderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
                <br/>
            <h3>Agenturdienstleistungen</h3>
                <br/>
            <p>Wir verarbeiten die Daten unserer Kunden im Rahmen unserer vertraglichen Leistungen zu denen konzeptionelle und strategische Beratung, Kampagnenplanung, Software- und Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen und Prozessen/ Handling, Serveradministration, Datenanalyse/ Beratungsleistungen und Schulungsleistungen geh&ouml;ren.</p>
            <p>Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern), Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos), Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten (z.B. im Rahmen der Auswertung und Erfolgsmessung von Marketingma&szlig;nahmen). Besondere Kategorien personenbezogener Daten verarbeiten wir grunds&auml;tzlich nicht, au&szlig;er wenn diese Bestandteile einer beauftragten Verarbeitung sind. Zu den Betroffenen geh&ouml;ren unsere Kunden, Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der Verarbeitung besteht in der Erbringung von Vertragsleistungen, Abrechnung und unserem Kundenservice. Die Rechtsgrundlagen der Verarbeitung ergeben sich aus Art. 6 Abs. 1 lit. b DSGVO (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse, Statistik, Optimierung, Sicherheitsma&szlig;nahmen). Wir verarbeiten Daten, die zur Begr&uuml;ndung und Erf&uuml;llung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn sie im Rahmen eines Auftrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags &uuml;berlassenen Daten handeln wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28 DSGVO und verarbeiten die Daten zu keinen anderen, als den auftragsgem&auml;&szlig;en Zwecken.</p>
            <p>Wir l&ouml;schen die Daten nach Ablauf gesetzlicher Gew&auml;hrleistungs- und vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre &uuml;berpr&uuml;ft; im Fall der gesetzlichen Archivierungspflichten erfolgt die L&ouml;schung nach deren Ablauf (6 J, gem. &sect; 257 Abs. 1 HGB, 10 J, gem. &sect; 147 Abs. 1 AO). Im Fall von Daten, die uns gegen&uuml;ber im Rahmen eines Auftrags durch den Auftraggeber offengelegt wurden, l&ouml;schen wir die Daten entsprechend den Vorgaben des Auftrags, grunds&auml;tzlich nach Ende des Auftrags.</p>
                <br/>
            <h3>Vertragliche Leistungen</h3>
                <br/>
            <p>Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie anderer Auftraggeber, Kunden, Mandanten, Klienten oder Vertragspartner (einheitlich bezeichnet als &bdquo;Vertragspartner&ldquo;) entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen gegen&uuml;ber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden Vertragsverh&auml;ltnis.</p>
            <p>Zu den verarbeiteten Daten geh&ouml;ren die Stammdaten unserer Vertragspartner (z.B., Namen und Adressen), Kontaktdaten (z.B. E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B., in Anspruch genommene Leistungen, Vertragsinhalte, vertragliche Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B., Bankverbindungen, Zahlungshistorie).</p>
            <p>Besondere Kategorien personenbezogener Daten verarbeiten wir grunds&auml;tzlich nicht, au&szlig;er wenn diese Bestandteile einer beauftragten oder vertragsgem&auml;&szlig;en Verarbeitung sind.</p>
            <p>Wir verarbeiten Daten, die zur Begr&uuml;ndung und Erf&uuml;llung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe, sofern diese f&uuml;r die Vertragspartner nicht evident ist, hin. Eine Offenlegung an externe Personen oder Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags &uuml;berlassenen Daten, handeln wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben.</p>
            <p>Im Rahmen der Inanspruchnahme unserer Onlinedienste, k&ouml;nnen wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung speichern. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Interessen der Nutzer am Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grunds&auml;tzlich nicht, au&szlig;er sie ist zur Verfolgung unserer Anspr&uuml;che gem. Art. 6 Abs. 1 lit. f. DSGVO erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.</p>
            <p>Die L&ouml;schung der Daten erfolgt, wenn die Daten zur Erf&uuml;llung vertraglicher oder gesetzlicher F&uuml;rsorgepflichten sowie f&uuml;r den Umgang mit etwaigen Gew&auml;hrleistungs- und vergleichbaren Pflichten nicht mehr erforderlich sind, wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre &uuml;berpr&uuml;ft wird; im &Uuml;brigen gelten die gesetzlichen Aufbewahrungspflichten.</p>
                <br/>
            <h3>Administration, Finanzbuchhaltung, B&uuml;roorganisation, Kontaktverwaltung</h3>
                <br/>
            <p>Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden, Interessenten, Gesch&auml;ftspartner und Websitebesucher betroffen. Der Zweck und unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, B&uuml;roorganisation, Archivierung von Daten, also Aufgaben die der Aufrechterhaltung unserer Gesch&auml;ftst&auml;tigkeiten, Wahrnehmung unserer Aufgaben und Erbringung unserer Leistungen dienen. Die L&ouml;schung der Daten im Hinblick auf vertragliche Leistungen und die vertragliche Kommunikation entspricht den, bei diesen Verarbeitungst&auml;tigkeiten genannten Angaben.</p>
            <p>Wir offenbaren oder &uuml;bermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftspr&uuml;fer sowie weitere Geb&uuml;hrenstellen und Zahlungsdienstleister.</p>
            <p>Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen Gesch&auml;ftspartnern, z.B. zwecks sp&auml;terer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grunds&auml;tzlich dauerhaft.</p>
                <br/>
                <h3>Kontaktaufnahme</h3>
                <br/>
            <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer k&ouml;nnen in einem Customer-Relationship-Management System (&ldquo;CRM System&rdquo;) oder vergleichbarer Anfragenorganisation gespeichert werden.</p>
            <p>Wir l&ouml;schen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir &uuml;berpr&uuml;fen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.</p>
                <br/>
            <h3>Hosting und E-Mail-Versand</h3>
                <br/>
            <p>Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverf&uuml;gungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazit&auml;t, Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.</p>
            <p>Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverf&uuml;gungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).</p>
                <br/>
            <h3>Erhebung von Zugriffsdaten und Logfiles</h3>
                <br/>
            <p>Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten &uuml;ber jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten geh&ouml;ren Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, &uuml;bertragene Datenmenge, Meldung &uuml;ber erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.</p>
            <p>Logfile-Informationen werden aus Sicherheitsgr&uuml;nden (z.B. zur Aufkl&auml;rung von Missbrauchs- oder Betrugshandlungen) f&uuml;r die Dauer von maximal 7 Tagen gespeichert und danach gel&ouml;scht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endg&uuml;ltigen Kl&auml;rung des jeweiligen Vorfalls von der L&ouml;schung ausgenommen.</p>
                <br/><h3>Onlinepr&auml;senzen in sozialen Medien</h3> <br/>
            <p>Wir unterhalten Onlinepr&auml;senzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort &uuml;ber unsere Leistungen informieren zu k&ouml;nnen.</p>
            <p>Wir weisen darauf hin, dass dabei Daten der Nutzer au&szlig;erhalb des Raumes der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen. Hierdurch k&ouml;nnen sich f&uuml;r die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden k&ouml;nnte. Im Hinblick auf US-Anbieter die unter dem Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass sie sich damit verpflichten, die Datenschutzstandards der EU einzuhalten.</p>
            <p>Ferner werden die Daten der Nutzer im Regelfall f&uuml;r Marktforschungs- und Werbezwecke verarbeitet. So k&ouml;nnen z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile k&ouml;nnen wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und au&szlig;erhalb der Plattformen zu schalten, die mutma&szlig;lich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner k&ouml;nnen in den Nutzungsprofilen auch Daten unabh&auml;ngig der von den Nutzern verwendeten Ger&auml;te gespeichert werden (insbesondere wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).</p>
            <p>Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern um eine Einwilligung in die Datenverarbeitung gebeten werden (d.h. ihr Einverst&auml;ndnis z.B. &uuml;ber das Anhaken eines Kontrollk&auml;stchens oder Best&auml;tigung einer Schaltfl&auml;che erkl&auml;ren) ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.</p>
            <p>F&uuml;r eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsm&ouml;glichkeiten (Opt-Out), verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.</p>
            <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden k&ouml;nnen. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und k&ouml;nnen direkt entsprechende Ma&szlig;nahmen ergreifen und Ausk&uuml;nfte geben. Sollten Sie dennoch Hilfe ben&ouml;tigen, dann k&ouml;nnen Sie sich an uns wenden.</p>
            <p>&ndash; Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) &ndash; Datenschutzerkl&auml;rung: <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/about/privacy/</a>, Opt-Out: <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">https://www.facebook.com/settings?tab=ads</a> und <a href="https://www.youronlinechoices.com" target="_blank" rel="noopener noreferrer">https://www.youronlinechoices.com</a>, Privacy Shield: <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>.</p>
            <p>&ndash; Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) &ndash; Datenschutzerkl&auml;rung: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>, Opt-Out: <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">https://adssettings.google.com/authenticated</a>, Privacy Shield: <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>.</p>
            <p>&ndash; Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) &ndash; Datenschutzerkl&auml;rung/ Opt-Out: <a href="https://instagram.com/about/legal/privacy/" target="_blank" rel="noopener noreferrer">https://instagram.com/about/legal/privacy/</a>.</p>
            <p>&ndash; Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) &ndash; Datenschutzerkl&auml;rung: <a href="https://twitter.com/de/privacy" target="_blank" rel="noopener noreferrer">https://twitter.com/de/privacy</a>, Opt-Out: <a href="https://twitter.com/personalization" target="_blank" rel="noopener noreferrer">https://twitter.com/personalization</a>, Privacy Shield: <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a>.</p>
            <p>&ndash; Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA) &ndash; Datenschutzerkl&auml;rung/ Opt-Out: <a href="https://about.pinterest.com/de/privacy-policy" target="_blank" rel="noopener noreferrer">https://about.pinterest.com/de/privacy-policy</a>.</p>
            <p>&ndash; LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland) &ndash; Datenschutzerkl&auml;rung <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/legal/privacy-policy</a> , Opt-Out: <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>, Privacy Shield:&nbsp;<a href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active</a>.</p>
            <p>&ndash; Xing (XING AG, Dammtorstra&szlig;e 29-32, 20354 Hamburg, Deutschland) &ndash; Datenschutzerkl&auml;rung/ Opt-Out: <a href="https://privacy.xing.com/de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">https://privacy.xing.com/de/datenschutzerklaerung</a>.</p>
            <p>&ndash; Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3 4PR, United Kingdom) &ndash; Datenschutzerkl&auml;rung/ Opt-Out: <a href="https://wakelet.com/privacy.html" target="_blank" rel="noopener noreferrer">https://wakelet.com/privacy.html</a>.</p>
            <p>&ndash; Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin, Deutschland) &ndash; Datenschutzerkl&auml;rung/ Opt-Out: <a href="https://soundcloud.com/pages/privacy" target="_blank" rel="noopener noreferrer">https://soundcloud.com/pages/privacy</a>.</p>
            <p><a href="https://datenschutz-generator.de" target="_blank" rel="nofollow noopener noreferrer">Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a></p>
        </div>{/* /.content */}
      </div>{/* /.container-sm */}
      <Footer/>
    </>
  )
}

export default Impressum
